import { AuthenticatedTemplate } from "@azure/msal-react";
import { memo } from "react";

import { isDevelopment } from "./const";

import Header from "./components/Header";
import AppRoutes from "./components/Routes";

function App() {
  return (
    <div className="App">
      <Header />
      {!isDevelopment ? (
        <AuthenticatedTemplate>
          <AppRoutes />
        </AuthenticatedTemplate>
      ) : (
        <AppRoutes />
      )}
    </div>
  );
}

export default memo(App);
