import { message } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";

export const isDevelopment = process.env.NODE_ENV === "development";

export const userGuide =
  "https://data-at-scale.docs.cloud.bayer.com/modules/policy-server/docs/user_guide/";
export const teamChannel =
  "https://teams.microsoft.com/l/channel/19%3a7CPIxhNYJQei-8x_wIxdh4MjaxWefdFSz5BSl2ksqhI1%40thread.tacv2/General?groupId=113a784c-eca0-4233-95d8-4add9ab5742a&tenantId=fcb2b37b-5da0-466b-9b83-0014b67a7c78";
export const mailToSupport = "mailto:rustem_agziamov@epam.com";
export const mailToProductOwner =
  "mailto:christian.rehne@bayer.com;rolf.grigat@bayer.com;torsten.gippert@bayer.com";

export const handleCopyJSON = (name: string) => {
  navigator.clipboard.writeText(name);
  message.success({
    content: "Pond name is copied",
    duration: 1,
  });
};

interface ITenantsTableRow {
  name: string;
  plugins: number;
  policies: number;
  lastUpdated: string;
}

export const TENANT_TABLE_COLUMNS: ColumnsType<ITenantsTableRow> = [
  {
    title: "Tenants",
    dataIndex: "name",
    key: "name",
    sorter: (a, b) => a.name.length - b.name.length,
    render: (value: string) => (
      <div className="name">
        <Link to={`/${value}`}>{value}</Link>
      </div>
    ),
  },
  {
    title: "Plugins",
    dataIndex: "plugins",
    key: "plugins",
    width: "104px",
    render: (value: number) => <div>{value}</div>,
    sorter: (a, b) => a.plugins - b.plugins,
  },
  {
    title: "Policies",
    dataIndex: "policies",
    key: "policies",
    width: "104px",
    render: (value: number) => <div>{value}</div>,
    sorter: (a, b) => a.policies - b.policies,
  },
  {
    title: "Last updated",
    dataIndex: "lastUpdated",
    key: "lastUpdated",
    width: "200px",
    render: (value: string) => <div>{value}</div>,
    sorter: (a, b) => a.lastUpdated.length - b.lastUpdated.length,
  },
];

export const mockTenantsArray = [
  {
    name: "Tenant1",
    plugins: 23,
    policies: 23,
    lastUpdated: "2022-04-24 18:00:00",
  },
  {
    name: "Tenant2",
    plugins: 1122,
    policies: 2,
    lastUpdated: "2022-04-24 18:00:00",
  },
  {
    name: "Tenant3",
    plugins: 23,
    policies: 1,
    lastUpdated: "2022-04-24 18:00:00",
  },
];

export const addTenantBreadcrumbs = [
  {
    path: "/",
    key: 1,
    breadcrumbName: "Tenants list",
  },
  {
    path: "/addTenant",
    key: 2,
    breadcrumbName: "Add tenant",
  },
];

export const tenantBreadcrumbs = [
  {
    path: "/",
    key: 1,
    breadcrumbName: "Tenants list",
  },
  {
    path: "/:id",
    key: 2,
    breadcrumbName: "Add tenant",
  },
];

export const initialTenant = {
  tenantId: "",
  description: "",
  token: {
    required: false,
    issuer: [],
    audience: [],
    appId: [],
    signer: [],
    age: {
      value: 0,
      type: "hours",
    },
    signature: false,
  },
};

export const initialConfigForm = {
  description: "",
  required: false,
  issuer: [],
  audience: [],
  appId: [],
  signer: [],
  age: {
    value: 0,
    type: "hours",
  },
  signature: false,
};
