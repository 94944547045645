import { ChangeEvent, memo, useCallback, useEffect, useState } from "react";
import {
  Card,
  Collapse,
  Descriptions,
  Empty,
  Input,
  message,
  Modal,
  Segmented,
  Switch,
} from "antd";
import {
  DatabaseOutlined,
  EditOutlined,
  KeyOutlined,
  LockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { SegmentedValue } from "antd/lib/segmented";

import {
  IPlugins,
  IPluginsList,
  ITenantConfig,
} from "../../pages/Tenant/types";
import { ICompletedTenant } from "../../pages/AddTenant/types";
import { EModes, INotification } from "../../pages/Tenants/types";
import { updateTenant } from "../../http/userApi";

import Button from "../../common/Button";
import Spinner from "../../common/Spinner";

import "./style.scss";

type IProps = {
  last_modified: string;
  name: string;
  config: ITenantConfig;
  plugins: IPlugins;
};

const Plugins = ({
  data,
  pluginsList,
  setPluginsList,
}: {
  data: IProps;
  pluginsList: IPluginsList[];
  setPluginsList: (value: IPluginsList[]) => void;
}) => {
  const [mode, setMode] = useState<SegmentedValue>("All");
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState<IPluginsList>();

  const [notification, setNotification] = useState<INotification | null>(null);

  useEffect(() => {
    if (notification && notification.mode === "error") {
      const modal = Modal[notification.mode]({
        title: notification.title,
        className: "notification-wrapper",
        mask: false,
        style: {
          top: 64,
          right: 24,
          position: "absolute",
        },
        okText: "Retry",
        okButtonProps: {
          style: {
            display: "none",
          },
        },
        cancelText: "Cancel",
        closable: true,
        maskClosable: false,
        afterClose() {
          setNotification(null);
          modal.destroy();
        },
      });
      setTimeout(() => {
        setNotification(null);
        modal.destroy();
      }, 5000);
    }
  }, [notification, setNotification]);

  const [search, setSearch] = useState("");

  const [searchedPlugins, setSearchedPlugins] = useState<IPluginsList[]>([]);

  useEffect(
    () =>
      setSearchedPlugins(
        pluginsList.filter(item =>
          item.name.toLowerCase().includes(search.toLowerCase()),
        ),
      ),
    [search, pluginsList, setPluginsList],
  );

  const handleSearchItems = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
    },
    [setSearch],
  );

  useEffect(() => {
    if (pluginsList.length) {
      setSelected({
        name: pluginsList[0].name,
        enabled: pluginsList[0].enabled,
        pluginType: pluginsList[0].pluginType,
      });
    }
  }, [pluginsList]);

  const handleChangePluginEnabled = useCallback(
    async (name: string | undefined, checked: boolean) => {
      try {
        setLoading(true);

        if (name) {
          const newData: ICompletedTenant = {
            name: data.name,
            description: "",
            plugins: {
              ...data.plugins,
              [name]: {
                enabled: checked,
              },
            },
            config: data.config,
          };

          const result: {
            data: string | null;
            error: null | string;
          } = await updateTenant(newData);

          if (result?.data) {
            if (selected?.name === name) {
              setSelected({
                ...selected,
                enabled: checked,
              });

              setPluginsList(
                pluginsList.map(plugin =>
                  plugin.name === name
                    ? { ...plugin, enabled: checked }
                    : plugin,
                ),
              );
            } else {
              setPluginsList(
                pluginsList.map(plugin =>
                  plugin.name === name
                    ? { ...plugin, enabled: checked }
                    : plugin,
                ),
              );
            }

            message.success({
              content: result.data,
              duration: 3,
            });
          }

          if (result.error) {
            setNotification({
              mode: EModes.Error,
              title: result.error,
            });
          }
        }
      } finally {
        setLoading(false);
      }
    },
    [
      data.config,
      data.name,
      data.plugins,
      selected,
      setPluginsList,
      pluginsList,
    ],
  );

  const handleSelected = useCallback(
    (title: string, isEnabled: boolean, type: string) => {
      setSelected({ name: title, enabled: isEnabled, pluginType: type });
    },
    [],
  );

  return (
    <div className="plugins-tab">
      <Card
        title="Plugins"
        bodyStyle={{
          padding: 0,
        }}
        className="plugins-tab-list"
        extra={
          <Segmented
            options={["All", "Installed", "Not installed"]}
            value={mode}
            onChange={setMode}
          />
        }
      >
        <div className="search-wrapper">
          <Input.Search
            placeholder="Search plugin..."
            onChange={handleSearchItems}
          />
        </div>
        <div className="card-body">
          <Collapse
            className="collapse-item"
            defaultActiveKey={["1", "2", "3", "4"]}
          >
            <Collapse.Panel header="Secrets" key="1">
              {mode === "All" ? (
                searchedPlugins.length &&
                searchedPlugins.find(item => item.pluginType === "Secrets") ? (
                  searchedPlugins
                    .filter(item => item.pluginType === "Secrets")
                    .map((item, i) => (
                      <div
                        onClick={() =>
                          handleSelected(
                            item.name,
                            item.enabled,
                            item.pluginType,
                          )
                        }
                        className={`plugins-item ${
                          selected && selected.name === item.name
                            ? "item-selected"
                            : ""
                        }`}
                        key={i}
                      >
                        <div className="info">
                          <div className="icon">
                            <KeyOutlined />
                          </div>
                          {item.name}
                        </div>
                        <Switch
                          checked={item.enabled}
                          loading={loading}
                          defaultChecked={item.enabled}
                          onChange={checked =>
                            handleChangePluginEnabled(item.name, checked)
                          }
                        />
                      </div>
                    ))
                ) : (
                  <Empty description="No available plugins" />
                )
              ) : mode === "Installed" ? (
                searchedPlugins.length &&
                searchedPlugins.find(item => item.pluginType === "Secrets") ? (
                  searchedPlugins
                    .filter(item => item.pluginType === "Secrets")
                    .filter(item => item.enabled)
                    .map((item, i) => (
                      <div
                        onClick={() =>
                          handleSelected(
                            item.name,
                            item.enabled,
                            item.pluginType,
                          )
                        }
                        className={`plugins-item ${
                          selected && selected.name === item.name
                            ? "item-selected"
                            : ""
                        }`}
                        key={i}
                      >
                        <div className="info">
                          <div className="icon">
                            <KeyOutlined />
                          </div>
                          {item.name}
                        </div>
                        <Switch
                          checked={item.enabled}
                          loading={loading}
                          defaultChecked={item.enabled}
                          onChange={checked =>
                            handleChangePluginEnabled(item.name, checked)
                          }
                        />
                      </div>
                    ))
                ) : (
                  <Empty description="No available plugins" />
                )
              ) : searchedPlugins.length &&
                searchedPlugins.find(item => item.pluginType === "Secrets") ? (
                searchedPlugins
                  .filter(item => item.pluginType === "Secrets")
                  .filter(item => !item.enabled)
                  .map((item, i) => (
                    <div
                      onClick={() =>
                        handleSelected(item.name, item.enabled, item.pluginType)
                      }
                      className={`plugins-item ${
                        selected && selected.name === item.name
                          ? "item-selected"
                          : ""
                      }`}
                      key={i}
                    >
                      <div className="info">
                        <div className="icon">
                          <KeyOutlined />
                        </div>
                        {item.name}
                      </div>
                      <Switch
                        checked={item.enabled}
                        loading={loading}
                        defaultChecked={item.enabled}
                        onChange={checked =>
                          handleChangePluginEnabled(item.name, checked)
                        }
                      />
                    </div>
                  ))
              ) : (
                <Empty description="No available plugins" />
              )}
            </Collapse.Panel>
            <Collapse.Panel header="User attributes" key="2">
              {mode === "All" ? (
                searchedPlugins.length &&
                searchedPlugins.find(
                  item => item.pluginType === "User attributes",
                ) ? (
                  searchedPlugins
                    .filter(item => item.pluginType === "User attributes")
                    .map((item, i) => (
                      <div
                        onClick={() =>
                          handleSelected(
                            item.name,
                            item.enabled,
                            item.pluginType,
                          )
                        }
                        className={`plugins-item ${
                          selected && selected.name === item.name
                            ? "item-selected"
                            : ""
                        }`}
                        key={i}
                      >
                        <div className="info">
                          <div className="icon user-attr">
                            <UserOutlined />
                          </div>
                          {item.name}
                        </div>
                        <Switch
                          checked={item.enabled}
                          loading={loading}
                          defaultChecked={item.enabled}
                          onChange={checked =>
                            handleChangePluginEnabled(item.name, checked)
                          }
                        />
                      </div>
                    ))
                ) : (
                  <Empty description="No available plugins" />
                )
              ) : mode === "Installed" ? (
                searchedPlugins.length &&
                searchedPlugins.find(
                  item => item.pluginType === "User attributes",
                ) ? (
                  searchedPlugins
                    .filter(item => item.pluginType === "User attributes")
                    .filter(item => item.enabled)
                    .map((item, i) => (
                      <div
                        onClick={() =>
                          handleSelected(
                            item.name,
                            item.enabled,
                            item.pluginType,
                          )
                        }
                        className={`plugins-item ${
                          selected && selected.name === item.name
                            ? "item-selected"
                            : ""
                        }`}
                        key={i}
                      >
                        <div className="info">
                          <div className="icon user-attr">
                            <UserOutlined />
                          </div>
                          {item.name}
                        </div>
                        <Switch
                          checked={item.enabled}
                          loading={loading}
                          defaultChecked={item.enabled}
                          onChange={checked =>
                            handleChangePluginEnabled(item.name, checked)
                          }
                        />
                      </div>
                    ))
                ) : (
                  <Empty description="No available plugins" />
                )
              ) : searchedPlugins.length &&
                searchedPlugins.find(
                  item => item.pluginType === "User attributes",
                ) ? (
                searchedPlugins
                  .filter(item => item.pluginType === "User attributes")
                  .filter(item => !item.enabled)
                  .map((item, i) => (
                    <div
                      onClick={() =>
                        handleSelected(item.name, item.enabled, item.pluginType)
                      }
                      className={`plugins-item ${
                        selected && selected.name === item.name
                          ? "item-selected"
                          : ""
                      }`}
                      key={i}
                    >
                      <div className="info">
                        <div className="icon user-attr">
                          <UserOutlined />
                        </div>
                        {item.name}
                      </div>
                      <Switch
                        checked={item.enabled}
                        loading={loading}
                        defaultChecked={item.enabled}
                        onChange={checked =>
                          handleChangePluginEnabled(item.name, checked)
                        }
                      />
                    </div>
                  ))
              ) : (
                <Empty description="No available plugins" />
              )}
            </Collapse.Panel>
            <Collapse.Panel header="Data attributes" key="3">
              {mode === "All" ? (
                searchedPlugins.length &&
                searchedPlugins.find(
                  item => item.pluginType === "Data attributes",
                ) ? (
                  searchedPlugins
                    .filter(item => item.pluginType === "Data attributes")
                    .map((item, i) => (
                      <div
                        onClick={() =>
                          handleSelected(
                            item.name,
                            item.enabled,
                            item.pluginType,
                          )
                        }
                        className={`plugins-item ${
                          selected && selected.name === item.name
                            ? "item-selected"
                            : ""
                        }`}
                        key={i}
                      >
                        <div className="info">
                          <div className="icon data-attr">
                            <DatabaseOutlined />
                          </div>
                          {item.name}
                        </div>
                        <Switch
                          checked={item.enabled}
                          loading={loading}
                          defaultChecked={item.enabled}
                          onChange={checked =>
                            handleChangePluginEnabled(item.name, checked)
                          }
                        />
                      </div>
                    ))
                ) : (
                  <Empty description="No available plugins" />
                )
              ) : mode === "Installed" ? (
                searchedPlugins.length &&
                searchedPlugins.find(
                  item => item.pluginType === "Data attributes",
                ) ? (
                  searchedPlugins
                    .filter(item => item.pluginType === "Data attributes")
                    .filter(item => item.enabled)
                    .map((item, i) => (
                      <div
                        onClick={() =>
                          handleSelected(
                            item.name,
                            item.enabled,
                            item.pluginType,
                          )
                        }
                        className={`plugins-item ${
                          selected && selected.name === item.name
                            ? "item-selected"
                            : ""
                        }`}
                        key={i}
                      >
                        <div className="info">
                          <div className="icon data-attr">
                            <DatabaseOutlined />
                          </div>
                          {item.name}
                        </div>
                        <Switch
                          checked={item.enabled}
                          loading={loading}
                          defaultChecked={item.enabled}
                          onChange={checked =>
                            handleChangePluginEnabled(item.name, checked)
                          }
                        />
                      </div>
                    ))
                ) : (
                  <Empty description="No available plugins" />
                )
              ) : searchedPlugins.length &&
                searchedPlugins.find(
                  item => item.pluginType === "Data attributes",
                ) ? (
                searchedPlugins
                  .filter(item => item.pluginType === "Data attributes")
                  .filter(item => !item.enabled)
                  .map((item, i) => (
                    <div
                      onClick={() =>
                        handleSelected(item.name, item.enabled, item.pluginType)
                      }
                      className={`plugins-item ${
                        selected && selected.name === item.name
                          ? "item-selected"
                          : ""
                      }`}
                      key={i}
                    >
                      <div className="info">
                        <div className="icon data-attr">
                          <DatabaseOutlined />
                        </div>
                        {item.name}
                      </div>
                      <Switch
                        checked={item.enabled}
                        loading={loading}
                        defaultChecked={item.enabled}
                        onChange={checked =>
                          handleChangePluginEnabled(item.name, checked)
                        }
                      />
                    </div>
                  ))
              ) : (
                <Empty description="No available plugins" />
              )}
            </Collapse.Panel>
            <Collapse.Panel header="Authorization" key="4">
              {mode === "All" ? (
                searchedPlugins.length &&
                searchedPlugins.find(
                  item => item.pluginType === "Authorization",
                ) ? (
                  searchedPlugins
                    .filter(item => item.pluginType === "Authorization")
                    .map((item, i) => (
                      <div
                        onClick={() =>
                          handleSelected(
                            item.name,
                            item.enabled,
                            item.pluginType,
                          )
                        }
                        className={`plugins-item ${
                          selected && selected.name === item.name
                            ? "item-selected"
                            : ""
                        }`}
                        key={i}
                      >
                        <div className="info">
                          <div className="icon auth">
                            <LockOutlined />
                          </div>
                          {item.name}
                        </div>
                        <Switch
                          checked={item.enabled}
                          loading={loading}
                          defaultChecked={item.enabled}
                          onChange={checked =>
                            handleChangePluginEnabled(item.name, checked)
                          }
                        />
                      </div>
                    ))
                ) : (
                  <Empty description="No available plugins" />
                )
              ) : mode === "Installed" ? (
                searchedPlugins.length &&
                searchedPlugins.find(
                  item => item.pluginType === "Authorization",
                ) ? (
                  searchedPlugins
                    .filter(item => item.pluginType === "Authorization")
                    .filter(item => item.enabled)
                    .map((item, i) => (
                      <div
                        onClick={() =>
                          handleSelected(
                            item.name,
                            item.enabled,
                            item.pluginType,
                          )
                        }
                        className={`plugins-item ${
                          selected && selected.name === item.name
                            ? "item-selected"
                            : ""
                        }`}
                        key={i}
                      >
                        <div className="info">
                          <div className="icon auth">
                            <LockOutlined />
                          </div>
                          {item.name}
                        </div>
                        <Switch
                          checked={item.enabled}
                          loading={loading}
                          defaultChecked={item.enabled}
                          onChange={checked =>
                            handleChangePluginEnabled(item.name, checked)
                          }
                        />
                      </div>
                    ))
                ) : (
                  <Empty description="No available plugins" />
                )
              ) : searchedPlugins.length &&
                searchedPlugins.find(
                  item => item.pluginType === "Authorization",
                ) ? (
                searchedPlugins
                  .filter(item => item.pluginType === "Authorization")
                  .filter(item => !item.enabled)
                  .map((item, i) => (
                    <div
                      onClick={() =>
                        handleSelected(item.name, item.enabled, item.pluginType)
                      }
                      className={`plugins-item ${
                        selected && selected.name === item.name
                          ? "item-selected"
                          : ""
                      }`}
                      key={i}
                    >
                      <div className="info">
                        <div className="icon auth">
                          <LockOutlined />
                        </div>
                        {item.name}
                      </div>
                      <Switch
                        checked={item.enabled}
                        loading={loading}
                        defaultChecked={item.enabled}
                        onChange={checked =>
                          handleChangePluginEnabled(item.name, checked)
                        }
                      />
                    </div>
                  ))
              ) : (
                <Empty description="No available plugins" />
              )}
            </Collapse.Panel>
          </Collapse>
        </div>
      </Card>
      {selected ? (
        <Card
          title={`${selected.pluginType} / ${selected.name}`}
          className="plugins-tab-description"
          bodyStyle={{
            padding: 0,
          }}
          extra={
            <div className="plugins-tab-descr-extra">
              <div className="enable">
                <span>
                  {selected.enabled
                    ? "Plugin is enabled"
                    : "Plugin is disabled"}
                </span>
                <Switch
                  checked={selected.enabled}
                  loading={loading}
                  onChange={checked =>
                    handleChangePluginEnabled(selected.name, checked)
                  }
                />
              </div>
              <Button size="large" icon={<EditOutlined />}>
                Edit
              </Button>
            </div>
          }
        >
          {loading ? (
            <Spinner />
          ) : (
            <>
              <div className="card-head">
                Plugin description <a href="#">Learn more</a>
              </div>
              <div className="card-body">
                <Card title="General" className="plugin-card">
                  <Descriptions>
                    <Descriptions.Item
                      label="Max AD groups"
                      labelStyle={{
                        width: 170,
                        color: "rgba(0, 0, 0, 0.45)",
                      }}
                    >
                      local.lambda.tenants.name
                    </Descriptions.Item>
                  </Descriptions>
                  <Descriptions>
                    <Descriptions.Item
                      label="Function name"
                      labelStyle={{
                        width: 170,
                        color: "rgba(0, 0, 0, 0.45)",
                      }}
                    >
                      aws_iam_role.lambda-tenants.arn
                    </Descriptions.Item>
                  </Descriptions>
                  <Descriptions>
                    <Descriptions.Item
                      label="Skip session tags"
                      labelStyle={{
                        width: 170,
                        color: "rgba(0, 0, 0, 0.45)",
                      }}
                    >
                      var.iam_session_max_groups
                    </Descriptions.Item>
                  </Descriptions>
                </Card>
              </div>
            </>
          )}
        </Card>
      ) : null}
    </div>
  );
};

export default memo(Plugins);
