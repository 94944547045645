import { memo, useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { message, PageHeader, Popover, Tabs } from "antd";
import {
  CopyOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import useFetch from "../../hooks/useFetch";
import { url } from "../../http";
import { Route } from "antd/lib/breadcrumb/Breadcrumb";
import { tenantBreadcrumbs } from "../../const";
import { IPlugins, IPluginsList, ITenantConfig } from "./types";
import { deleteTenantRequest } from "../../http/userApi";

import Spinner from "../../common/Spinner";
import Button from "../../common/Button";
import General from "../../components/General";
import PluginsTab from "../../components/PluginsTab";

import "./style.scss";

const Tenant = () => {
  const { id } = useParams();
  const [visible, setVisible] = useState(false);
  const [pluginsList, setPluginsList] = useState<IPluginsList[]>([]);
  const [loading, setLoading] = useState(false);
  const [tabKey, setTabKey] = useState<string>("1");
  const navigate = useNavigate();

  const { data, isLoading } = useFetch<{
    last_modified: string;
    description: string;
    name: string;
    config: ITenantConfig;
    plugins: IPlugins;
  }>(`${url}/v1/tenants/${id}/`);

  useEffect(() => {
    if (data && data.plugins) {
      for (const [name, value] of Object.entries(data.plugins)) {
        if (name.includes("Secrets")) {
          setPluginsList((item): IPluginsList[] | [] => {
            if (item.length) {
              return [
                ...item,
                { name: name, enabled: value.enabled, pluginType: "Secrets" },
              ];
            } else {
              return [
                { name: name, enabled: value.enabled, pluginType: "Secrets" },
              ];
            }
          });
        } else if (name.includes("UserAttrs")) {
          setPluginsList((item): IPluginsList[] | [] => {
            if (item.length) {
              return [
                ...item,
                {
                  name: name,
                  enabled: value.enabled,
                  pluginType: "User attributes",
                },
              ];
            } else {
              return [
                {
                  name: name,
                  enabled: value.enabled,
                  pluginType: "User attributes",
                },
              ];
            }
          });
        } else if (name.includes("Data")) {
          setPluginsList((item): IPluginsList[] | [] => {
            if (item.length) {
              return [
                ...item,
                {
                  name: name,
                  enabled: value.enabled,
                  pluginType: "Data attributes",
                },
              ];
            } else {
              return [
                {
                  name: name,
                  enabled: value.enabled,
                  pluginType: "Data attributes",
                },
              ];
            }
          });
        } else if (name.includes("Auth")) {
          setPluginsList((item): IPluginsList[] | [] => {
            if (item.length) {
              return [
                ...item,
                {
                  name: name,
                  enabled: value.enabled,
                  pluginType: "Authorization",
                },
              ];
            } else {
              return [
                {
                  name: name,
                  enabled: value.enabled,
                  pluginType: "Authorization",
                },
              ];
            }
          });
        }
      }
    }
  }, [data, data?.plugins]);

  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
  };

  const handleTabChange = (key: string) => {
    setTabKey(key);
  };

  const itemRender = (route: Route) => {
    if (route.path === "/:id") {
      return <div>{data?.name}</div>;
    } else {
      return <Link to={route.path}>{route.breadcrumbName}</Link>;
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(window.location.href);
    message.success({
      content: "Tenant link is copied",
      duration: 1,
    });
  };

  const deleteTenant = useCallback(async () => {
    setLoading(true);

    if (id) {
      const result: {
        data: string | null;
        error: null | string;
      } = await deleteTenantRequest(id);

      if (result.data) {
        navigate("/");
        message.success({
          content: result.data,
          duration: 2,
        });
      }

      if (result.error) {
        message.error({
          content: "Something wrong",
          duration: 2,
        });
      }
    }

    setLoading(false);
  }, [id, navigate]);

  const content = (
    <div className="popup">
      <div className="popup-question">
        <ExclamationCircleOutlined />
        <span>Are you sure you want to delete this tenant?</span>
      </div>
      <div className="popup-buttons">
        <Button type="default" size="small" className="popup-btn">
          No
        </Button>
        <Button
          type="primary"
          size="small"
          loading={loading}
          onClick={deleteTenant}
        >
          Yes
        </Button>
      </div>
    </div>
  );

  return (
    <div className="tenant-page">
      {isLoading ? (
        <Spinner className="spinner-tenant" />
      ) : (
        <>
          <PageHeader
            title={`Tenant: ${data?.name}`}
            className="breadcrumbs"
            breadcrumb={{
              routes: tenantBreadcrumbs,
              itemRender,
            }}
            extra={
              <div className="header-buttons">
                <Popover
                  content={content}
                  trigger="click"
                  visible={visible}
                  onVisibleChange={handleVisibleChange}
                >
                  <Button
                    className="delete-btn"
                    size="large"
                    type="link"
                    icon={<DeleteOutlined />}
                  >
                    Delete tenant
                  </Button>
                </Popover>
                <Button
                  size="large"
                  onClick={handleCopy}
                  icon={<CopyOutlined />}
                >
                  Copy link
                </Button>
              </div>
            }
            footer={
              <Tabs defaultActiveKey="1" onChange={handleTabChange}>
                <Tabs.TabPane tab="General" key="1" />
                <Tabs.TabPane tab="Plugins" key="2" />
                <Tabs.TabPane tab="Policy" key="3" />
                <Tabs.TabPane tab="Log" key="4" />
              </Tabs>
            }
          />
        </>
      )}
      {data ? (
        tabKey === "1" ? (
          <General
            name={data.name}
            descr={data.description}
            plugins={data.plugins}
            config={data?.config}
          />
        ) : tabKey === "2" ? (
          <PluginsTab
            data={data}
            pluginsList={pluginsList}
            setPluginsList={setPluginsList}
          />
        ) : null
      ) : null}
    </div>
  );
};

export default memo(Tenant);
