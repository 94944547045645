import axios, { AxiosError } from "axios";

export const getHeaders = () => ({
  "x-api-key": process.env.REACT_APP_API_ACCESS_KEY || "",
  "Content-Type": "application/json",
});

export const convertBytes = (bytes: number) => {
  let result = "";

  const kb = bytes / 1024,
    mb = kb / 1024,
    gb = mb / 1024;

  if (kb <= 1024) {
    result = kb.toFixed(3) + " KB";
  } else if (kb >= 1024 && mb <= 1024) {
    result = mb.toFixed(3) + " MB";
  } else if (mb >= 1024 && gb <= 1024) {
    result = gb.toFixed(3) + " GB";
  }

  return result;
};

export const formatDateTime = (number: number) => {
  const timestamp = new Date(number * 1000);
  const dtFormat = new Intl.DateTimeFormat("en-GB", {
    dateStyle: "medium",
    timeStyle: "medium",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone || "UTC",
  });

  return dtFormat.format(new Date(timestamp));
};

export const handleRequestError = (error: AxiosError | { message: string }) => {
  if (axios.isAxiosError(error)) {
    if (error.response?.status === 422) {
      return {
        error: (
          error.response.data as {
            detail: Array<{
              msg: string;
            }>;
          }
        ).detail[0].msg,
        data: null,
      };
    } else {
      return {
        error: (error.response?.data as { detail: string }).detail,
        data: null,
      };
    }
  } else {
    return {
      data: null,
      error: (error as { message: string }).message,
    };
  }
};
