import { memo, useEffect, useState } from "react";
import { Form, message, Modal, PageHeader } from "antd";
import { Route } from "antd/lib/breadcrumb/Breadcrumb";
import { Link, useNavigate } from "react-router-dom";

import { addTenantBreadcrumbs, initialTenant } from "../../const";
import { EModes, INotification, ITenant } from "../Tenants/types";
import { ICompletedTenant } from "./types";
import { createTenant } from "../../http/userApi";

import Button from "../../common/Button";
import Spinner from "../../common/Spinner";
import GeneralForm from "../../components/GeneralForm";
import TokenForm from "../../components/TokenForm";

import "./style.scss";

const AddTenant = () => {
  const [data, setData] = useState<ITenant>(initialTenant);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);

  const [notification, setNotification] = useState<INotification | null>(null);

  useEffect(() => {
    if (notification && notification.mode === "error") {
      const modal = Modal[notification.mode]({
        title: notification.title,
        className: "notification-wrapper",
        mask: false,
        style: {
          top: 64,
          right: 24,
          position: "absolute",
        },
        okText: "Retry",
        okButtonProps: {
          style: {
            display: "none",
          },
        },
        cancelText: "Cancel",
        closable: true,
        maskClosable: false,
        afterClose() {
          setNotification(null);
          modal.destroy();
        },
      });
      setTimeout(() => {
        setNotification(null);
        modal.destroy();
      }, 5000);
    }
  }, [notification, setNotification, navigate]);

  const itemRender = (route: Route) => {
    if (route.path === "/addTenant") {
      return <div>{route.breadcrumbName}</div>;
    } else {
      return <Link to={route.path}>{route.breadcrumbName}</Link>;
    }
  };

  const handleSave = async () => {
    try {
      setLoading(true);

      const newData: ICompletedTenant = {
        name: data.tenantId,
        description: "",
        plugins: {
          SecretsIamSession: {
            enabled: true,
            pattern: "arn:aws:iam::*:role/*",
            SKIP_SESSION_TAGS: false,
            MAX_AD_GROUPS: 3,
          },
          SecretsIamUser: {
            enabled: true,
            pattern: "arn:aws:iam::*:user/*",
            IAM_GROUP_TARGET_PREFIX: "_dpadu-asauta-test-",
            IAM_USER_SESSION_LIFETIME: "1",
          },
          AuthAllowAll: {
            enabled: true,
          },
          AuthUserAttrs: {
            enabled: true,
            requirements: {
              dept: "",
              internal_network: false,
              request_ip_countries: [],
              countries: [],
              internal_email: false,
              bayer_domain: false,
              emails: [],
              roles: [],
              roles_claim: [],
              managers: [],
              groups: [],
            },
          },
        },
        config: {
          ENV_NAME: "asauta-mttest",
          REQUIRE_TOKEN: data.token.required,
          TARGET_ROLE_ARN:
            "arn:aws:iam::473416480675:role/oauth/oauth-asauta-mttest-iam-authzms-access-role",
          AWS_REGION: "eu-central-1",
          AWS_ACCOUNT_ID: 473416480675,
          AZURE_TENANT: data.token.signer.join(";"),
          AWS_SFTP_SERVER_REGION: "bar",
          OIDC_ISSUER: data.token.issuer.join(";"),
          OIDC_AUDIENCE: data.token.audience.join(";"),
          OIDC_APPID: data.token.appId.join(";"),
          MAX_TOKEN_AGE:
            data.token.age.type === "days"
              ? data.token.age.value * 24
              : data.token.age.value,
          VERIFY_TOKEN_SIGNATURE: data.token.signature,
        },
      };

      const result: {
        data: string | null;
        error: null | string;
      } = await createTenant(newData);

      if (result?.data) {
        navigate("/");
        message.success({
          content: result.data,
          duration: 3,
        });
      }

      if (result.error) {
        setNotification({
          mode: EModes.Error,
          title: result.error,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const addButtons = [
    <Link to="/" key="1">
      <Button>Cancel</Button>
    </Link>,
    <Button onClick={handleSave} key="submit" htmlType="submit" type="primary">
      Save
    </Button>,
  ];

  return (
    <div className="add-tenant">
      <PageHeader
        title="Add tenant"
        className="breadcrumbs"
        breadcrumb={{
          routes: addTenantBreadcrumbs,
          itemRender,
        }}
        extra={addButtons}
      />
      <div className="add-tenant-body">
        {isLoading ? (
          <Spinner />
        ) : (
          <Form
            name="basic"
            autoComplete="off"
            requiredMark={false}
            layout="vertical"
          >
            <GeneralForm data={data} setData={setData} />
            <TokenForm data={data} setData={setData} />
          </Form>
        )}
      </div>
    </div>
  );
};

export default memo(AddTenant);
