import React, { ChangeEvent, Dispatch, useCallback } from "react";
import { Input, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import Button from "../../common/Button";

import "./style.scss";

const { Search } = Input;
const { Title } = Typography;

interface IProps {
  setSearchItems: Dispatch<string>;
}

const ListPageHeader: React.FC<IProps> = ({ setSearchItems }) => {
  const handleSearchItems = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchItems(e.target.value);
    },
    [setSearchItems],
  );

  return (
    <div className="list-header">
      <Title level={2} className="lh-title">
        Tenants list
      </Title>
      <div className="lh-wrapper">
        <Search
          placeholder="Search tenants by name"
          className="lh-search"
          onChange={handleSearchItems}
        />
        <Link to="/create">
          <Button icon={<PlusOutlined />} type="primary" size="large">
            Add tenant
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default React.memo(ListPageHeader);
