export type ITenant = {
  tenantId: string;
  description: string;
  token: {
    required: boolean;
    issuer: Array<string>;
    audience: Array<string>;
    appId: Array<string>;
    signer: Array<string>;
    age: { value: number; type: string };
    signature: boolean;
  };
};

export enum EModes {
  Success = "success",
  Error = "error",
}

export interface INotification {
  mode: EModes;
  title: string;
}
