import { memo, useCallback, useState } from "react";
import { Popover } from "antd";

import "./style.scss";

const ListPopup = ({ list }: { list: Array<string> }) => {
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = useCallback(
    (newVisible: boolean) => {
      setVisible(newVisible);
    },
    [setVisible],
  );

  return (
    <div>
      <ul className="popup-list">
        {list.map((subItem, index) => {
          if (index < 2) {
            return <li key={subItem}>{subItem}</li>;
          }
        })}
      </ul>
      {list.length > 2 ? (
        <Popover
          content={
            <ul className="popup-list">
              {list.map((item, index) => {
                if (index >= 2) {
                  return <li key={item}>{item}</li>;
                }
              })}
            </ul>
          }
          trigger="click"
          visible={visible}
          onVisibleChange={handleVisibleChange}
        >
          <div className="tenantList-button">
            <span></span>
          </div>
        </Popover>
      ) : null}
    </div>
  );
};

export default memo(ListPopup);
