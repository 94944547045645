import { $host } from "./index";
import { AxiosError } from "axios";
import { getHeaders, handleRequestError } from "../helpers";
import { ICompletedTenant } from "../pages/AddTenant/types";

export const createTenant = async (
  tenant: ICompletedTenant,
): Promise<{
  data: string | null;
  error: null | string;
}> => {
  try {
    await $host.post("/v1/tenants", tenant, {
      headers: getHeaders(),
    });

    return {
      error: null,
      data: "Tenant has been successfully created",
    };
  } catch (error) {
    return handleRequestError(error as AxiosError | { message: string });
  }
};

export const deleteTenantRequest = async (
  id: string,
): Promise<{
  data: string | null;
  error: null | string;
}> => {
  try {
    await $host.delete(`/v1/tenants/${id}/`, {
      headers: getHeaders(),
    });

    return {
      error: null,
      data: "Tenant was deleted successfully",
    };
  } catch (error) {
    return handleRequestError(error as AxiosError | { message: string });
  }
};

export const updateTenant = async (
  tenant: ICompletedTenant,
): Promise<{
  data: string | null;
  error: null | string;
}> => {
  try {
    await $host.put(`/v1/tenants/${tenant.name}/`, tenant, {
      headers: getHeaders(),
    });

    return {
      error: null,
      data: "Tenant has been successfully updated",
    };
  } catch (error) {
    return handleRequestError(error as AxiosError | { message: string });
  }
};
